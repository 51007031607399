import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import { initializeApp } from 'firebase/app'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import { markAsVerified } from '@/application/services/phone-verify'
import { getLocalStorageValue, setLocalStorageValue } from '@/application/utils/localstorage'
import { useStore } from 'vuex'

export const hideVerificationModal = (nextOpenAfterSeconds) => {
  setLocalStorageValue('verify-modal-closed', 'Y', nextOpenAfterSeconds)
}

export const usePhoneVerification = () => {
  const i18n = useI18n()
  const store = useStore()
  const license = computed(() => store.getters.getLicense)
  const openFromHome = computed(() => store.getters.getVerificationOpened)
  const modalWrapper = ref(null)
  const form = ref(null)
  const disableFirstButtons = ref(false)
  const isDisabled = ref(false)
  const phoneNumber = ref(null)
  const canResendAfter = ref(null)
  const confirmed = ref(false)
  const signInError = ref(false)
  const wrongCodeError = ref(true)
  const serverVerificationError = ref(false)
  const modalWasClosed = ref(getLocalStorageValue('verify-modal-closed'))
  const firebaseConfig = JSON.parse(process.env.VUE_APP_FIREBASE_APP_CONFIG)
  const app = initializeApp(firebaseConfig)

  const needConfirmation = ref(false)
  const requestsTerm = ref(null)
  watch(() => license.value, (value) => {
    if (value) {
      needConfirmation.value = Object.prototype.hasOwnProperty.call(value, 'phone_number_verified') && !value.phone_number_verified
      phoneNumber.value = value.phone_number
      requestsTerm.value = {
        count: value.phone_number_verify_requests,
        term: value.phone_number_verify_term,
      }
    }
  })

  watch(() => openFromHome.value, (value) => {
    if (value && modalWasClosed) {
      modalWasClosed.value = false
    }
  })

  initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(process.env.VUE_APP_GOOGLE_RECAPTCHA_SITEKEY),
  })
  const resendDisabled = ref(true)
  const showVerificationCodeField = ref(false)
  const confirmationResultRef = ref(null)

  const activateResending = () => {
    canResendAfter.value = 30
    const k = setInterval(() => {
      canResendAfter.value = canResendAfter.value - 1
      if (canResendAfter.value === 0) {
        resendDisabled.value = false
        clearInterval(k)
      }
    }, 1000)
  }

  const initCaptcha = () => {
    const auth = getAuth()
    auth.languageCode = i18n.locale.value
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'send-verification-code', {
      size: 'invisible',
      callback: (response) => {
        disableFirstButtons.value = true
        send(phoneNumber.value)
      },
    })
    window.recaptchaVerifier.render()
  }

  const send = (phoneNumber) => {
    resendDisabled.value = true
    const auth = getAuth()
    auth.languageCode = i18n.locale.value
    signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier)
      .then((confirmationResult) => {
        showVerificationCodeField.value = true
        activateResending()
        confirmationResultRef.value = confirmationResult
      }).catch(() => {
        signInError.value = true
      })
  }

  const resend = () => {
    window.recaptchaVerifier.render().then(function(widgetId) {
      window.grecaptcha.reset(widgetId)
      send(phoneNumber.value)
    })
  }

  return {
    isDisabled,
    resendDisabled,
    resend: resend,
    submit: async(value) => {
      isDisabled.value = true
      if (confirmationResultRef.value) {
        try {
          await confirmationResultRef.value.confirm(value.verificationCode)
          confirmed.value = true
        } catch (exception) {
          wrongCodeError.value = true
          form.value.setFieldError('verificationCode', i18n.t('forms.verifyPhone.errors.wrongCode'))
        }
        isDisabled.value = false

        if (confirmed.value) {
          showVerificationCodeField.value = false
          try {
            await markAsVerified()
            store.dispatch('markPhoneAsVerified')
              .then(() => store.dispatch('getLicense'))
          } catch (exception) {
            serverVerificationError.value = true
          }
        }
      }
    },
    closeModal: (nextOpenAfterSeconds) => {
      if (nextOpenAfterSeconds) {
        hideVerificationModal(nextOpenAfterSeconds)
      }
      store.dispatch('closeVerification')
      modalWrapper.value.close()
    },
    showVerificationCodeField,
    initCaptcha,
    confirmed,
    canResendAfter,
    signInError,
    wrongCodeError,
    modalWrapper,
    form,
    modalWasClosed,
    serverVerificationError,
    needConfirmation,
    requestsTerm: requestsTerm,
    phoneNumber,
    disableFirstButtons,
    openFromHome,
  }
}
